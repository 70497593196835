import React from "react";
import Layout from "../../components/layout";
import courses from "../../images/live.jpg";

const IndexPage = () => {

  return (
    <Layout>
      <hr />
      <div
        style={{
          color: "black",
          paddingLeft: "4rem",
          paddingRight: "4rem",
          textAlign: "center",
          marginTop: "4rem",
        }}
      >
        <div class="py-16">
          <div class="container m-auto px-6">
            <div class="lg:flex justify-between items-center">
              <div class="lg:w-6/12 lg:p-0 p-7">
                <h1 class="text-4xl font-bold leading-tight mb-5 ">
                  {" "}
                  Live classes
                </h1>
                <p class="text-xl">
                  {" "}
                  Live classes can be streamed live to your students or they can
                  view the recording at their own convenience. -Simply start
                  streaming your class and it will be automatically saved for
                  viewing later. -Inflowkit also allows you to schedule classes
                  for future streaming.
                </p>

                <div class="py-5">
                  <a
                    href="#"
                    class="text-white rounded-full py-2 px-5 text-lg font-semibold bg-purple-600 inline-block border border-purple-600 mr-3"
                  >
                    Try for free
                  </a>
                </div>
              </div>
              <div class="lg:w-5/12 order-2">
                <img
                  src={courses}
                  className="shadow-lg rounded max-w-full h-auto align-middle border-none"
                  style={{
                    transform:
                      "scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)",
                  }}
                ></img>

              </div>
            </div>
          </div>
        </div>

        <div class="py-10 mb-10">
          <div>
            <section class="text-gray-200 bg-gray-900">
              <div class="max-w-6xl mx-auto px-5 py-24 ">
                <div class="text-center mb-20">
                  <h1 class=" title-font  mb-4 text-4xl font-extrabold leading-10 tracking-tight sm:text-5xl sm:leading-none md:text-6xl">
                    Live classes features
                  </h1>
                  <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                    With a list of features that’ll have you scratching your
                    head for hours, Inflowkit is a godsend to anyone who’s ever
                    caught the online teaching bug.
                  </p>
                  <div class="flex mt-6 justify-center">
                    <div class="w-16 h-1 rounded-full bg-indigo-500 inline-flex"></div>
                  </div>
                </div>
                <section class="flex flex-row flex-wrap mx-auto">
                  <div class="transition-all duration-150 flex w-full px-4 py-6 md:w-1/2 lg:w-1/3">
                    <div class="flex flex-col items-stretch min-h-full pb-4 mb-6 transition-all duration-150  rounded-lg shadow-lg hover:shadow-2xl">
                      <div class="flex flex-wrap items-center  px-4 py-1 text-center mx-auto">
                        <a href="#" class="hover:underline">
                          <h2 class="text-2xl font-bold tracking-normal text-white">
                            Reminders
                          </h2>
                        </a>
                      </div>
                      <hr class="border-gray-300" />
                      <p class="flex flex-row flex-wrap w-full px-4 py-2 overflow-hidden text-sm text-justify text-white">
                        Schedule reminders to your students via email, sms
                        (coming soon) so that none of your students ever miss
                        class again.
                      </p>
                    </div>
                  </div>

                  <div class="transition-all duration-150 flex w-full px-4 py-6 md:w-1/2 lg:w-1/3">
                    <div class="flex flex-col items-stretch min-h-full pb-4 mb-6 transition-all duration-150  rounded-lg shadow-lg hover:shadow-2xl">
                      <div class="flex flex-wrap items-center  px-4 py-1 text-center mx-auto">
                        <a href="#" class="hover:underline">
                          <h2 class="text-2xl font-bold tracking-normal text-white">
                            Record and share your best live classes
                          </h2>
                        </a>
                      </div>
                      <hr class="border-gray-300" />
                      <p class="flex flex-row flex-wrap w-full px-4 py-2 overflow-hidden text-sm text-justify text-white">
                        Immediately capture every moment of your live video
                        class, then share recorded versions with your students
                        right after the broadcast.
                      </p>
                    </div>
                  </div>

                  <div class="transition-all duration-150 flex w-full px-4 py-6 md:w-1/2 lg:w-1/3">
                    <div class="flex flex-col items-stretch min-h-full pb-4 mb-6 transition-all duration-150  rounded-lg shadow-lg hover:shadow-2xl">
                      <div class="flex flex-wrap items-center  px-4 py-1 text-center mx-auto">
                        <a href="#" class="hover:underline">
                          <h2 class="text-2xl font-bold tracking-normal text-white">
                            Works With Popular Video Conferencing Apps
                          </h2>
                        </a>
                      </div>
                      <hr class="border-gray-300" />
                      <p class="flex flex-row flex-wrap w-full px-4 py-2 overflow-hidden text-sm text-justify text-white">
                        Our live class feature works with popular video
                        conferencing apps such as zoom no need to learn from
                        scratch
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
